import React, { useState } from 'react';
import './Faq.css';

function FAQ() {
  const faqData = [
    {
      question: 'Quais são os requisitos para obter a guarda de um filho?',
      answer:
        'Os requisitos para obter a guarda de um filho variam de acordo com as leis locais. Geralmente, fatores como estabilidade emocional, ambiente adequado e capacidade de prover cuidados são considerados.'
    },
    {
      question: 'Como funciona o processo de divórcio?',
      answer:
        'O processo de divórcio envolve várias etapas, incluindo a apresentação de petições, divisão de propriedades e, se aplicável, questões de guarda e pensão alimentícia. Recomenda-se a consulta a um advogado para orientação personalizada.'
    },
    {
      question: 'Como posso proteger meus direitos durante um divórcio litigioso?',
      answer:
        'Durante um divórcio litigioso, é crucial contar com o suporte de um advogado especializado em direito de família. Nós trabalhamos para proteger seus interesses e garantir uma resolução justa para questões como divisão de bens, guarda de filhos e pensão alimentícia.'
    },
    {
      question: 'Quais são as opções disponíveis para a guarda de filhos em casos de divórcio?',
      answer:
        'Oferecemos orientação sobre as diferentes opções de guarda de filhos, incluindo guarda conjunta, guarda exclusiva e visitação. Nosso objetivo é encontrar a solução mais adequada às necessidades da sua família, sempre priorizando o bem-estar dos seus filhos.'
    },
    {
      question: 'Como lidar com casos de alienação parental?',
      answer:
        'A alienação parental pode ser uma situação delicada. Nossos advogados têm experiência em lidar com casos desse tipo, trabalhando para proteger o relacionamento entre pais e filhos. Entre em contato para discutir estratégias legais para enfrentar essa situação.'
    },
    {
      question: 'Quais são os passos legais para modificar acordos de pensão alimentícia?',
      answer:
        'Se você está enfrentando mudanças financeiras significativas, é possível modificar acordos de pensão alimentícia. Nós ajudamos a avaliar sua situação e guiamos você pelos processos legais para buscar ajustes justos e equitativos.'
    },
    {
      question: 'Como posso garantir meus direitos em questões de violência doméstica?',
      answer:
        'Caso esteja enfrentando situações de violência doméstica, é fundamental buscar ajuda legal imediatamente. Podemos orientá-lo sobre as medidas protetivas disponíveis e trabalhar para garantir sua segurança e a de seus familiares.'
    }
    // Adicione mais perguntas e respostas conforme necessário
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-wrapper">
      <div className='faq-container'>
        <h2>Perguntas Frequentes</h2>
        <div className='faq-list'>
          {faqData.map((item, index) => (
            <div key={index} className='faq-item'>
              <div
                className={`faq-question ${activeIndex === index ? 'active' : ''}`}
                onClick={() => handleToggle(index)}
              >
                <div className='question-text'>{item.question}</div>
                <div className='toggle-icon'>{activeIndex === index ? '-' : '+'}</div>
              </div>
              <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
                {item.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQ;

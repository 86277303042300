import React from 'react';
import './Footer.css';

const Footer = ({ scrollToComponent }) => {
  const main = '/';

  return (
    <div className='footer__container'>
      <div className='footer'>
        <div className='footer__child'>
          <div>
            <h3>SOBRE</h3>
            <p>
              Você está passando por questões delicadas envolvendo o direito de família? Como advogado especializado, estou aqui para oferecer orientação jurídica sólida e soluções personalizadas para suas necessidades específicas.
            </p>
          </div>

          <div className='footer-icons'>
            <h3>REDES</h3>
            <div className='footer-icons-content'>
              <a href=""><i className='uil uil-facebook-f'></i></a>
              <a href="https://www.instagram.com/avanteadvocacia_/" target='_blank' rel="noreferrer"><i className='uil uil-instagram'></i></a>
              <a href="https://wa.me/5584991161350" target='_blank' rel="noreferrer"><i className='uil uil-whatsapp'></i></a>
            </div>
          </div>
        </div>


        <div className='footer__child footer__menu'>
          <h3>MENU</h3>

          <button className="main-btn" onClick={() => {
            scrollToComponent("HeroRef");
          }}>Início</button >

          <button className="main-btn"
            onClick={() => {
              scrollToComponent("ServicosRef");
            }}
          >
            Áreas de Atuação
          </button>

          <button className="main-btn"
            onClick={() => {
              scrollToComponent("SobreRef");
            }}
          >
            Sobre
          </button>

          <button className="main-btn"
            onClick={() => {
              scrollToComponent("FaqRef");
            }}
          >
            Perguntas Frequentes
          </button>
          <button className="main-btn" onClick={() => {
            scrollToComponent("ContatoRef");
          }}>Contato</button >
        </div>
      </div>

      <div className='copy__container'>
        <div className='copy'>
          {' '}
          <p>
            Desenvolvido por{' '}
            <a href='https://jacquesgomes.com.br/portfolio/'>Jacques Gomes</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;


import React from "react";
import "./Diferenciais.css";

function DiferenciaisCard() {
  return (
    <div className="diferenciais-card">
      <h2>Por que nos escolher?</h2>
      <ul>
        <li>
          <h3>Especialização Profunda</h3>
          <p>
            Como advogado especialista em direito de família, concentro-me
            exclusivamente nessas questões para garantir a qualidade no serviço.
          </p>
        </li>
        <li>
          <h3>Atendimento Pessoal</h3>
          <p>
            Receba atenção direta e soluções adaptadas às suas necessidades
            exclusivas, prezamos pelo atendimento artesanal e personalizado.
          </p>
        </li>
        <li>
          <h3>Agilidade e Eficiência</h3>
          <p>
            Minha missão é alcançar resultados positivos para você, trabalhando
            diligentemente em seu nome, valorizando a rapidez e eficiência.
          </p>
        </li>
      </ul>
    </div>
  );
}

export default DiferenciaisCard;

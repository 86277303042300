import React from "react";
import "./Sobre.css";
import Perfil from '../../assets/imgs/perfil.jpeg';

function Sobre() {
  return (
    <div className="sobre-wrapper">
      <div className="sobre-container">
        <div className="sobre-image">
          <img
              src={Perfil}
              alt='Advogado de Direito de Família'
            />
        </div>
        <div className="sobre-text">
          <h2>Conte comigo para representar seus direitos</h2>
          <h3>ARTHUR LOPES - OAB/RN 17307</h3>
          <div>
            <ul>
              
              <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
              <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
              <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
              <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
              <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
            </ul>
          </div>

          <div className="sobre-btn-container">
            <a
              href="https://wa.me/5584991161350"
              target="_blank"
              rel="noreferrer"
              className="main-btn"
            >
              Saiba mais
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sobre;

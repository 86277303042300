import React from "react";
import "./Hero.css";
import HeroLogo from "../../assets/imgs/logotipo-horizontal-bege.png";
import Familia from "../../assets/imgs/pexels-caleb-oquendo-3030090.jpg";

function Hero() {
  return (
    <div className="hero-wrapper">

      <div className="image-overlay"></div>
      <div className="hero-container hero-section">
        <div className="hero-content text-container">
          <h1>Especialista em Direito de Família e Sucessões</h1>
          <p>
            Você está passando por questões delicadas envolvendo o direito de
            família? Como advogado especializado, estou aqui para oferecer
            orientação jurídica sólida e soluções personalizadas para suas
            necessidades específicas.
          </p>
          <a href="https://wa.me/5584991161350" target="_blank" rel="noreferrer" className="main-btn">Fale conosco agora!</a>
        </div>
      </div>
    </div>
  );
}

export default Hero;

import React from "react";
import "./Contato.css";
import Logo from "../../assets/imgs/AL_Versão Principal Azul.svg";

function Contato() {
  return (
    <div className="contato-container">
      <div className="contato-img-container">
        <img src={Logo} alt="logo"></img>
      </div>
      <div className="contato-content">
        <span>
          <i class="uil uil-phone"></i>
          <p>(84) 99116-1350</p>
        </span>

        <span>
          <i class="uil uil-at"></i>
          <p>contato@arthurlopesadv.com.br</p>
        </span>

        <span>
          <i class="uil uil-instagram"></i>
          <p>avanteadvocacia_</p>
        </span>

        <span>
          <i class="uil uil-map-marker"></i>
          <p>
            R. Des. Régulo Tinoco, 1302, Barro Vermelho, Natal - RN, 59022-545
          </p>
        </span>

        <a href="https://wa.me/5584991161350" target="_blank" rel="noreferrer" className="main-btn">
          Fale conosco agora pelo WhatsApp
        </a>
      </div>
    </div>
  );
}

export default Contato;

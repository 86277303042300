import React from 'react';
import './Cta.css';

function Cta() {
  return (
    <div className='cta-container'>
        <h2>Entre em contato! Estamos te esperando!</h2>
          <p>Não deixe de entrar em contato com um advogado experiente e comprometido em fornecer orientação legal especializada.</p>
      <a href="https://wa.me/5584991161350" target="_blank" rel="noreferrer" className="main-btn">Fale conosco agora pelo WhatsApp</a>
    </div>
  );
}

export default Cta;
